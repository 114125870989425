"use client";

import { routeType } from "@/data/routes";
import { useAppSelector } from "@/redux/store";
import classNames from "classnames";
import { Badge } from "ui/components/badges";
import { SearchBold } from "ui/components/inputs";
import { IoDocumentTextOutline } from "ui/react-icons/io5";
import style from "./GlobalSearcher.module.scss";

type MatchType = {
  indices: number[];
  value: string;
  key: string;
  refIndex: number;
};

export type RouteResultType = {
  refIndex: number;
  score: number;
  matches: MatchType[];
  item: routeType;
};

type Props = {
  search: string;
  result: RouteResultType;
  onClick: (item: routeType) => void;
};

const GlobalSearchPageItem = ({ search, result, onClick }: Props) => {
  const company = useAppSelector((state) => state.companies.companySelected);

  return (
    <div
      role="link"
      onClick={() => {
        onClick(result.item);
      }}
      tabIndex={0}
      className={classNames("pb-1 pl-1 pt-1 row a-center clickable br-0", style.globalsearcher)}
      style={{ cursor: "pointer" }}
    >
      <IoDocumentTextOutline size={24} className="ml-1 mr-1" />
      <div className="column">
        <div className="row a-center">
          {result.item?.isCompanySlugRouteRequired && company && <Badge style="main">{company?.name}</Badge>}

          <SearchBold as="p" className="m-0 fw-600 mb-05 fs-100" search={search} str={result.item.h1} />
        </div>

        <SearchBold as="p" className="fs-80 fw-500 mt-0 mb-0" style={{ color: "var(--color-gray14)" }} search={search} str={result.item.subtitle} />

        <div className="row wrap mt-05 gap-05">
          {result?.item?.tags?.map((tag) => {
            return (
              <Badge
                className="row a-center"
                key={`${result.item.url}_${tag}`}
                style={result.matches.some((item) => item.key === "tags" && item.value == tag) ? "main-light" : "gray"}
              >
                <span>#</span>
                <span>{tag}</span>
              </Badge>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default GlobalSearchPageItem;
