import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/expert/app/_components/globalSearcher/ModalGlobalSearcher.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/expert/components/layout/ToastWrapper.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Providers"] */ "/vercel/path0/apps/expert/redux/provider.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/expert/styles/main.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@15.1.6_644dd6b114c10ef995d1ca9daefe8dd2/node_modules/next/font/google/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"Montserrat\",\"arguments\":[{\"subsets\":[\"latin\"],\"weight\":[\"200\",\"300\",\"400\",\"500\",\"600\",\"700\",\"800\"]}],\"variableName\":\"montserrat\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/ui/components/layout/copyright/Copyright.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/ui/components/layout/header/Header.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/ui/components/layout/headerUserLayout/HeaderUserLayout.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/ui/styles/app.scss");
