"use client";

import { setGlobalSearchIsOpen } from "@/redux/slices/NavigationSlice";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import classNames from "classnames";
import { useRouter } from "next/navigation";
import { Badge } from "ui/components/badges";
import { Grid } from "ui/components/blocs";
import { IconButton } from "ui/components/buttons";
import { SearchBold } from "ui/components/inputs";
import { CompanyPicture } from "ui/components/pictures";
import { CompanyType } from "utilities/redux/types";

type Props = {
  company: CompanyType;
  search;
  onclick: (company: CompanyType) => void;
  isFocus: boolean;
  filterMode: "accountingFirm" | "user" | "add" | "notification";
  actionFocus: number;
};

const GlobalSearchCompanyItem = ({ company, search, filterMode, onclick, isFocus, actionFocus }: Props) => {
  const user = useAppSelector((state) => state.users.user);
  const affected = useAppSelector((state) => state.companies.companiesAffected);
  const router = useRouter();
  const dispatch = useAppDispatch();

  const hasAccess =
    user?.roles?.includes("ROLE_EXPERT_OWNER") ||
    company?.isRestricted == false ||
    affected.some((item) => item.id === company.id);

  return (
    <Grid
      alignItems="center"
      template={company?.code?.length > 0 ? "auto auto 1fr auto" : "auto 1fr auto"}
      space={8}
      onClick={() => onclick(company)}
      className={classNames("pt-1 pb-1 pl-1 pr-1  br-0 m-0", {
        clickable: !company?.deletedAt,
        "not-allowed": !hasAccess,
      })}
      style={{
        background: company?.deletedAt && "var(--color-errorBackground)",
      }}
    >
      <CompanyPicture app="expert" company={company} size={40} />
      <>
        {company.code ? (
          <Badge style="main-light" size="small">
            <SearchBold search={search} str={company.code} />
          </Badge>
        ) : (
          <></>
        )}
      </>

      <div className="row a-center">
        <SearchBold search={search} str={company.name} as="p" className="fs-85 fw-600 m-0" style={{ color: company?.deletedAt && "var(--color-error)" }} />

        {company?.isRestricted && (
          <>
            {!hasAccess ? (
              <IconButton tooltip="Accès interdit" icon="lock" form="circle" type="error" size={12} />
            ) : (
              <IconButton
                tooltip={"Accès restreint \n mais accessible"}
                icon="lock-open"
                form="circle"
                type="success"
                size={12}
              />
            )}
            {affected?.some((item) => item.id === company.id) && (
              <IconButton icon="user" form="circle" type="success" size={12} />
            )}
          </>
        )}
      </div>

      <div className="row gap-05">
        <IconButton
          form="circle"
          icon={"company"}
          tooltip="Fiche dossier"
          type={isFocus && actionFocus == 0 ? "primary" : "gray-light"}
          tabIndex={0}
          cb={() => {
            router.push("/dashboard/dossiers/" + company?.slug);
            dispatch(setGlobalSearchIsOpen(false));
          }}
        />
        <IconButton
          form="circle"
          icon={"upload"}
          tooltip="Nouvelle importation"
          type={isFocus && actionFocus == 1 ? "primary" : "gray-light"}
          tabIndex={0}
          cb={() => {
            router.push("/dashboard/dossiers/" + company?.slug + "/importation/nouvelle-importation");
            dispatch(setGlobalSearchIsOpen(false));
          }}
        />
        <IconButton
          form="circle"
          icon={"question"}
          tooltip="Points en suspens"
          type={isFocus && actionFocus == 2 ? "primary" : "gray-light"}
          tabIndex={0}
          cb={() => {
            router.push("/dashboard/dossiers/" + company?.slug + "/pes-gestion/a-valider");
            dispatch(setGlobalSearchIsOpen(false));
          }}
        />
        <IconButton
          form="circle"
          icon={"exchange"}
          tooltip="Echanges de questions"
          type={isFocus && actionFocus == 3 ? "primary" : "gray-light"}
          tabIndex={0}
          cb={() => {
            router.push("/dashboard/dossiers/" + company?.slug + "/echanges-questions");
            dispatch(setGlobalSearchIsOpen(false));
          }}
        />
        <IconButton
          form="circle"
          icon={"graph"}
          tooltip="Graphiques"
          type={isFocus && actionFocus == 4 ? "primary" : "gray-light"}
          tabIndex={0}
          cb={() => {
            router.push("/dashboard/dossiers/" + company?.slug + "/tableau-de-bord");
            dispatch(setGlobalSearchIsOpen(false));
          }}
        />
      </div>

      {/* ::::: LOCK ::::::: */}
    </Grid>
  );
};

export default GlobalSearchCompanyItem;
