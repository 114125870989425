"use client";

import { useEffect, useRef, useState } from "react";
import { SearchInputText, ToggleInput } from "ui/components/inputs";
import { Modal } from "ui/components/modals";
import { routes } from "../../../data/routes";

import { expertFetcher } from "@/lib/http";
import { setAccountingFirCompanies } from "@/redux/slices/AccountingFirmSlice";
import { setCompanyAffected, setCompanySelected } from "@/redux/slices/CompanySlice";
import { setFilterCompanies, setFilterPages, setGlobalSearchIsOpen } from "@/redux/slices/NavigationSlice";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import { useParams, usePathname, useRouter } from "next/navigation";
import { Grid } from "ui/components/blocs";
import { IconButton } from "ui/components/buttons";
import { MessageBox } from "ui/components/infos";
import { IoCloseCircle } from "ui/react-icons/io5";
import { PiArrowFatDownFill, PiArrowFatLeftFill, PiArrowFatRightFill, PiArrowFatUpFill } from "ui/react-icons/pi";
import { RiSpace } from "ui/react-icons/ri";
import { CompanyType } from "utilities/redux/types";
import GlobalSearchCompanyItem from "./GlobalSearchCompanyItem";
import GlobalSearchPageItem from "./GlobalSearchPageItem";
import style from "./GlobalSearcher.module.scss";

const Fuse = require("fuse.js");

const ModalGlobalSearcher = ({ }) => {
  const dispatch = useAppDispatch();
  const pathName = usePathname();
  const router = useRouter();
  //
  const user = useAppSelector((state) => state.users.user);
  const globalSearchIsOpen = useAppSelector((state) => state.navigation.globalSearchIsOpen);
  const companies = useAppSelector((state) => state.accountingFirm.accountingFirCompanies) ?? [];
  const affectations = useAppSelector((state) => state.companies?.companiesAffected) ?? [];
  const slug = useParams()?.slug;

  const includePages = useAppSelector((state) => state.navigation.filterPages) ?? true;
  const includeCompanies = useAppSelector((state) => state.navigation.filterCompanies) ?? true;
  const affected = useAppSelector((state) => state.companies.companiesAffected);

  const inputRef = useRef<HTMLInputElement>(null);
  const [search, setSearch] = useState("");

  //// filtered
  const [companyFilter, setCompanyFilter] = useState<"accountingFirm" | "user">("accountingFirm");
  const [fuseResults, setFuseResults] = useState([]);
  const [resultsFiltered, setResultsFiltered] = useState([]);

  const toggleModal = (e) => dispatch(setGlobalSearchIsOpen(e));
  const listRef = useRef([]);
  const [selectedIndex, setSelectedIndex] = useState(undefined); /// Flèches haut bas
  const [actionFocus, setActionFocus] = useState(null); /// Flèches gauches droites sur companytem

  const dataToSearch = useRef([]);

  // Stocker l'instance de Fuse
  const fuseRef = useRef(
    new Fuse([], {
      isCaseSensitive: false,
      includeScore: true,
      includeMatches: true,
      minMatchCharLength: 1,
      ignoreLocation: true,
      threshold: 0.4,
      distance: 20,
      keys: ["company.code", "name", "h1", "subtitle", { name: "tags", weight: 0.2 }],
    }),
  );

  const fetchAllCompanies = (signal: AbortSignal) => {
    // Fusionner entreprises et routes
    dataToSearch.current = [
      ...routes.map((page) => ({ ...page, type: "page" })),
      ...companies.map((company) => ({ name: company.name, type: "company", company })),
    ];
    // Mettre à jour l'instance de Fuse avec les nouvelles données
    fuseRef.current.setCollection(dataToSearch.current);
    // console.log(dataToSearch.current)
    /// prevent loop if no user
    user &&
      pathName &&
      pathName.includes("/auth") == false &&
      pathName.includes("/error/403") == false &&
      expertFetcher
        .post("/expert/company/searchMyCompanies", { search: "", searchType: null }, { signal: signal })
        .then((resp) => {
          if (resp?.status === 200) {
            dispatch(setAccountingFirCompanies(resp.data.companies));
            dispatch(setCompanyAffected(resp.data.affectations));
          }
        });
  };

  // Fetch des entreprises et mise à jour des données de recherche
  useEffect(() => {
    let controller = new AbortController();

    if (user && pathName && pathName !== "/" && !pathName.includes("/auth") && !pathName.includes("/error/403")) {
      fetchAllCompanies(controller.signal);
    }
    return () => controller.abort();
  }, [user, pathName]);

  // Gestion de la recherche
  const handleSearch = (e) => {
    setSearch(e);
    let fused = fuseRef.current.search(e);
    setFuseResults(fused);
    refilterByType(fused);
  };

  const refilterByType = (data) => {
    let filtered = data.filter((result) => {
      ///// COMPANY
      if (result.item.type === "company" && includeCompanies) {
        if (companyFilter === "accountingFirm") {
          return true;
        }
        if (companyFilter === "user") {
          return affectations?.some((item) => item.id === result.item.company.id);
        }
      }

      // PAGES

      if (result.item.type === "page" && includePages == true && result.item.isCompanySlugRouteRequired) {
        // -> condition sur le slug de la route
        return pathName.includes("/dashboard/dossiers/") && slug;
      }

      // -> condition sur l'accès au cabinet
      return (
        result.item.type === "page" &&
        includePages == true &&
        (user.roles.includes("ROLE_EXPERT_OWNER") || result.item.restricted === false)
      );
    });

    /// Transformation des SLUGS des routes par le slug de la company en cours
    let transformed = filtered.map((result) => {
      if (result.item.isCompanySlugRouteRequired) {
        let rempalceme = {
          ...result,
          item: JSON.parse(JSON.stringify({ ...result.item, url: result.item.url.replace("[slug]", slug) })),
        };

        return rempalceme;
      }
      return result;
    });

    setResultsFiltered(transformed);
  };

  /// Refilter on change filter
  useEffect(() => {
    refilterByType(fuseResults);
  }, [includeCompanies, includePages, companyFilter, slug]);

  // /// RACOURCIS CLAVIERS /////////////////

  const handleSpaceRedirect = () => {
    let item = resultsFiltered[selectedIndex].item;

    if (item.type == "company") {
      handleClickCompanyItem(item.company);
    }
    if (item.type == "page") {
      handleClickPageItem(item);
    }
  };

  const handleKeyDown = (e) => {
    if (listRef.current?.length == 0) return;

    if (e.code === "Space" && selectedIndex !== null) {
      e.preventDefault();
      handleSpaceRedirect();
    }

    if (e.key === "ArrowDown" || e.key === "ArrowUp") {
      e.preventDefault();

      setSelectedIndex((prevIndex) => {
        if (prevIndex === undefined) {
          return 0;
        }

        if (e.key === "ArrowDown") {
          return prevIndex + 1 < listRef.current.length ? prevIndex + 1 : 0;
        }
        if (e.key === "ArrowUp") {
          return prevIndex > 0 ? prevIndex - 1 : listRef.current.length - 1;
        }

        return prevIndex;
      });
    }

    if (e.key === "ArrowRight" || e.key === "ArrowLeft") {
      e.preventDefault();
      resultsFiltered[selectedIndex].item.type == "company";

      if (e.key === "ArrowLeft") {
        setActionFocus((prev) => (prev > 1 ? prev - 1 : 0));
      }
      if (e.key === "ArrowRight") {
        setActionFocus((prev) => (prev < 4 && prev != null ? prev + 1 : 0));
      }
    }
  };

  /// Reset Modal on quit
  useEffect(() => {
    globalSearchIsOpen == true && inputRef?.current?.focus();
    setSearch("");
    setSelectedIndex(null);
    setActionFocus(null);
  }, [globalSearchIsOpen]);

  useEffect(() => {
    if (globalSearchIsOpen == true && resultsFiltered?.length > 0) {
      window.addEventListener("keydown", handleKeyDown);
    } else {
      window.removeEventListener("keydown", handleKeyDown);
      setSelectedIndex(null);
      setActionFocus(null);
    }
    return () => window.removeEventListener("keydown", handleKeyDown);
  }, [inputRef, globalSearchIsOpen, handleKeyDown, resultsFiltered]);

  useEffect(() => {
    selectedIndex != undefined && listRef.current[selectedIndex]?.focus();
  }, [selectedIndex, handleKeyDown]);

  //// au click
  const handleClickCompanyItem = (company: CompanyType) => {
    const hasAccess =
      user?.roles?.includes("ROLE_EXPERT_OWNER") ||
      company?.isRestricted == false ||
      affected.some((item) => item.id === company.id);
    if (hasAccess) {
      dispatch(setGlobalSearchIsOpen(false));
      dispatch(setCompanySelected(company));
      if (actionFocus) {
        //  Si il y a un bouton sélectionné par -> et <-
        switch (actionFocus) {
          case 0:
            return router.push(`/dashboard/dossiers/${company.slug}`);
          case 1:
            return router.push(`/dashboard/dossiers/${company.slug}/importation/nouvelle-importation`);
          case 2:
            return router.push(`/dashboard/dossiers/${company.slug}/pes-gestion/a-valider`);
          case 3:
            return router.push(`/dashboard/dossiers/${company.slug}/echanges-questions`);
          case 4:
            return router.push(`/dashboard/dossiers/${company.slug}/tableau-de-bord`);
        }
      } else {
        router.push(`/dashboard/dossiers/${company.slug}`);
      }
    }
  };

  const handleClickPageItem = (item) => {
    dispatch(setGlobalSearchIsOpen(false));
    router.push(item.url);
  };

  return (
    <>
      {user && !pathName.includes("/auth/") && pathName !== "/" && (
        <Modal maxWidth={800} className="pl-0" showModal={globalSearchIsOpen} setShowModal={toggleModal}>
          <div className="relative">
            <SearchInputText
              onFocus={() => {
                setActionFocus(null);
                setSelectedIndex(null);
              }}
              inputRef={inputRef}
              legend="Recherche"
              value={search}
              setter={handleSearch}
            />
            {search?.length > 0 && (
              <IoCloseCircle
                style={{ position: "absolute", right: "6px", bottom: "5px" }}
                size={40}
                className="clickable"
                color="var(--color-text)"
                onClick={() => {
                  setActionFocus(null);
                  setSelectedIndex(null);
                  setSearch("");
                }}
              />
            )}
          </div>

          <Grid template={"2fr 2fr 1fr"} className="mt-1" space={8}>
            <div
              className="tile gray-light br-2 h-100 pl-2 j-center column thin m-0"
              style={{ opacity: includeCompanies ? 1 : 0.5 }}
            >
              <div className="row a-center">
                <ToggleInput
                  legend="Dossier"
                  className="p-0 m-0"
                  value={includeCompanies}
                  setter={(e) => {
                    dispatch(setFilterCompanies(e));
                  }}
                />
                <div className="row a-center gap-05 ml-2">
                  <IconButton
                    icon="accountingFirm"
                    form="circle"
                    disabled={!includeCompanies}
                    type={companyFilter == "accountingFirm" ? "info" : "info-light"}
                    cb={() => setCompanyFilter("accountingFirm")}
                    tooltip="Dossiers cabinets"
                  />
                  <IconButton
                    icon="user"
                    form="circle"
                    disabled={!includeCompanies}
                    type={companyFilter == "user" ? "success" : "success-light"}
                    cb={() => setCompanyFilter("user")}
                    tooltip="Dossiers Affectés"
                  />
                </div>
              </div>
            </div>

            <div
              className="tile a-center j-center br-2 row w-100 h-100 gray-light  column thin pl-2 m-0"
              style={{ opacity: includePages ? 1 : 0.5 }}
            >
              <ToggleInput
                legend="Pages Syndemos"
                className="p-0 m-0"
                value={includePages}
                setter={(e) => {
                  dispatch(setFilterPages(e));
                }}
              />
            </div>
            <div className="tile gray-light m-0 h-100 br-2">
              <h4>Naviguer au clavier</h4>

              <div className="row a-center gap-05">
                <div className="tile gray row j-center  p-05 br-1 m-0">
                  <PiArrowFatDownFill color="var(--color-gray16)" size={14} />
                </div>
                <div className="tile gray row j-center p-05 br-1  m-0">
                  <PiArrowFatUpFill color="var(--color-gray16)" size={14} />
                </div>
                <div className="tile gray row j-center p-05 br-1  m-0">
                  <PiArrowFatLeftFill color="var(--color-gray16)" size={14} />
                </div>
                <div className="tile gray row j-center p-05 br-1 m-0">
                  <PiArrowFatRightFill color="var(--color-gray16)" size={14} />
                </div>

                <span className="fs-80">PUIS</span>
                <div className="tile gray row j-center p-05 br-1">
                  <RiSpace color="var(--color-gray16)" size={18} />
                </div>
              </div>
            </div>
          </Grid>

          {search.length > 0 ? (
            <div>
              <h4 className="mb-0">{resultsFiltered.length} Résultats</h4>
              <div className="tile gray-light br-3 p-0" style={{ overflowY: "auto", maxHeight: "500px" }}>
                {resultsFiltered.map((result, key) => {
                  return (
                    <div
                      ref={(el) => {
                        listRef.current[key] = el;
                      }}
                      className={style.searchItem}
                      key={key}
                      tabIndex={0}
                    >
                      {result.item.type === "company" ? (
                        <GlobalSearchCompanyItem
                          isFocus={key == selectedIndex}
                          actionFocus={actionFocus}
                          onclick={handleClickCompanyItem}
                          company={result?.item?.company}
                          search={search}
                          filterMode={companyFilter}
                        />
                      ) : (
                        <GlobalSearchPageItem result={result} search={search} onClick={handleClickPageItem} />
                      )}
                      {key + 1 != resultsFiltered?.length && includeCompanies && <hr className="m-0" />}
                    </div>
                  );
                })}
              </div>
            </div>
          ) : (
            /// TODO Ajouter des suggestions de pages
            <></>
            // <MessageBox className="mt-1">
            //   <p className="fw-700 fs-100">Commencez votre recherche</p>
            //   <p>Indiquez un nom de dossier ou une page spécifique</p>
            // </MessageBox>
          )}

          {resultsFiltered?.length === 0 && search.length > 0 && (
            <MessageBox direction="horizontal" className="mt-1">
              Aucun résultat ne correspond à votre recherche <strong>{search}</strong>
            </MessageBox>
          )}
        </Modal>
      )}
    </>
  );
};

export default ModalGlobalSearcher;
