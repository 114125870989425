/// TAGS
const TAG_CREATION = "Création";
const TAG_API = "API";
const TAG_CONNECTOR = "Connecteur";
const TAG_COMPANY = "Dossier";
const TAG_ACCOUNTING_FIRM = "Gestion cabinet";
const TAG_FACTURATION = "Facturation";
const TAG_MYPROFIL = "Mon profil";
//
const TAG_TEAM = "Equipe";
const TAG_ACCOUNTING_FIRM_USERS = "Collaborateurs";
const TAG_COMPANY_USERS = "Utilisateurs dossier";
const TAG_INFORMATIONS = "Informations";
//
const TAG_POINT_EN_SUSPENS = "Points en suspens";
const TAG_PES = "PES";
//
const TAG_DATA = "Data";
const TAG_KPI = "KPI";
const TAG_GRAPHIQUE = "Graphique";
//////

export type routeType = {
  url: string;
  h1: string;
  subtitle: string;
  restricted: boolean;
  isCompanySlugRouteRequired?: boolean;
  namespace: "company" | "accountingFirm" | "myprofil" | "information" | "report";
  tags?: string[];
};

export const routes: routeType[] = [
  {
    url: "/dashboard/dossiers/ajout-dossier/manuellement",
    h1: "Ajouter un dossier manuellement",
    subtitle: "Remplir les informations d'un formulaire de création un dossier",
    restricted: false,
    namespace: "company",
    tags: [TAG_CREATION, TAG_COMPANY],
  },
  {
    url: "/dashboard/dossiers/ajout-dossier",
    h1: "Ajout dossier",
    subtitle: "Choisir la méthode de création du dossier",
    restricted: false,
    namespace: "company",
    tags: [TAG_CREATION, TAG_API, TAG_CONNECTOR, TAG_COMPANY],
  },
  {
    url: "/dashboard/dossiers/ajout-dossier/via-api",
    h1: "Importation dossiers depuis API",
    subtitle: "Gérer l'importation des dossiers en lien avec l'API sélectionnée",
    restricted: false,
    namespace: "company",
    tags: [TAG_CREATION, TAG_API, TAG_CONNECTOR, TAG_COMPANY],
  },
  {
    url: "/dashboard/dossiers/ajout-dossier/via-csv",
    h1: "Ajout via CSV",
    subtitle: "Ajout multiples de dossiers à partir d'un fichier CSV",
    restricted: false,
    namespace: "company",
    tags: [TAG_CREATION, TAG_COMPANY],
  },
  {
    url: "/dashboard/dossiers",
    h1: "Liste des dossiers",
    subtitle: "Consultation et gestion des points en suspens relatifs à un dossier",
    restricted: false,
    namespace: "company",
    tags: [TAG_CREATION, TAG_COMPANY],
  },
  {
    url: "/dashboard/dossiers/[slug]/comptes",
    h1: "Comptes",
    subtitle: "Création et gestion des comptes du dossier",
    restricted: false,
    isCompanySlugRouteRequired: true,
    namespace: "company",
    tags: [TAG_COMPANY],
  },
  {
    url: "/dashboard/dossiers/[slug]/echanges-questions",
    h1: "Echanges de questions",
    subtitle: "Liste des échanges des points en suspens",
    restricted: false,
    isCompanySlugRouteRequired: true,
    namespace: "company",
    tags: [TAG_COMPANY, TAG_POINT_EN_SUSPENS, TAG_PES],
  },
  {
    url: "/dashboard/dossiers/[slug]/exercices",
    h1: "Exercices du dossier",
    subtitle: "Gestion des exercices du dossier",
    restricted: false,
    isCompanySlugRouteRequired: true,
    namespace: "company",
    tags: [TAG_COMPANY],
  },
  {
    url: "/dashboard/dossiers/[slug]/gestion-categories",
    h1: "Gestion des catégories",
    subtitle: "Création et modification de catégories personnalisées",
    restricted: false,
    isCompanySlugRouteRequired: true,
    namespace: "company",
    tags: [TAG_COMPANY],
  },
  {
    url: "/dashboard/dossiers/[slug]/gestion-salaries/creation",
    h1: "Création d'un utilisateur",
    subtitle: "Création d'un utilisateur pour le dossier en question",
    restricted: false,
    isCompanySlugRouteRequired: true,
    namespace: "company",
    tags: [TAG_COMPANY, TAG_COMPANY_USERS, TAG_CREATION],
  },
  {
    url: "/dashboard/dossiers/[slug]/gestion-salaries",
    h1: "Gestion salariés",
    subtitle: "Edition, modification et supppression des utilisateurs reliés à un dossier",
    restricted: false,
    isCompanySlugRouteRequired: true,
    namespace: "company",
    tags: [TAG_COMPANY, TAG_COMPANY_USERS],
  },
  {
    url: "/dashboard/dossiers/[slug]/gestion-salaries/relier-utilisateur-existant",
    h1: "Relier un utilisateur existant",
    subtitle: "Relier un utilisateur existant au dossier",
    restricted: false,
    isCompanySlugRouteRequired: true,
    namespace: "company",
    tags: [TAG_COMPANY, TAG_COMPANY_USERS, TAG_CREATION],
  },
  {
    url: "/dashboard/dossiers/[slug]/historique",
    h1: "Historique du dossier",
    subtitle: "Résumé de l'ensemble des actions du dossier",
    restricted: false,
    isCompanySlugRouteRequired: true,
    namespace: "company",
    tags: [TAG_COMPANY],
  },
  {
    url: "/dashboard/dossiers/[slug]/importation/generation-questions",
    h1: "Génération de questions",
    subtitle: "Création de questions automatisée grâce à un import d'écritures",
    restricted: false,
    isCompanySlugRouteRequired: true,
    namespace: "company",
    tags: [TAG_COMPANY, TAG_POINT_EN_SUSPENS, TAG_PES],
  },
  {
    url: "/dashboard/dossiers/[slug]/importation/nouvelle-importation",
    h1: "Nouvelle importation",
    subtitle: "Importer de nouvelles écritures depuis un FEC ou une API",
    restricted: false,
    isCompanySlugRouteRequired: true,
    namespace: "company",
    tags: [TAG_COMPANY, TAG_CREATION],
  },
  {
    url: "/dashboard/dossiers/[slug]/importation",
    h1: "Importation écritures comptables",
    subtitle: "Gestion des précédentes importations et générations de points en suspens",
    restricted: false,
    isCompanySlugRouteRequired: true,
    namespace: "company",
    tags: [TAG_COMPANY],
  },
  {
    url: "/dashboard/dossiers/[slug]/journaux",
    h1: "Journaux",
    subtitle: "Gestion des Journaux",
    restricted: false,
    isCompanySlugRouteRequired: true,
    namespace: "company",
    tags: [TAG_COMPANY],
  },
  {
    url: "/dashboard/dossiers/[slug]/modification-informations",
    h1: "Modification informations",
    subtitle: "Modification des informations du dossier",
    restricted: false,
    isCompanySlugRouteRequired: true,
    namespace: "company",
    tags: [TAG_COMPANY],
  },
  {
    url: "/dashboard/dossiers/[slug]",
    h1: "Page dossier",
    subtitle: "Consultation des informations du dossier",
    restricted: false,
    isCompanySlugRouteRequired: true,
    namespace: "company",
    tags: [TAG_COMPANY],
  },
  {
    url: "/dashboard/dossiers/[slug]/pes-gestion/a-envoyer",
    h1: "Points en suspens à envoyer",
    subtitle: "Les points en suspens préalablement validés et prêt à être envoyé au client",
    restricted: false,
    isCompanySlugRouteRequired: true,
    namespace: "company",
    tags: [TAG_COMPANY, TAG_POINT_EN_SUSPENS, TAG_PES],
  },
  {
    url: "/dashboard/dossiers/[slug]/pes-gestion/a-traiter",
    h1: "Points en suspens à traiter",
    subtitle: "Prise de décision sur les réponses aux PES envoyées par le dossier",
    restricted: false,
    isCompanySlugRouteRequired: true,
    namespace: "company",
    tags: [TAG_COMPANY, TAG_POINT_EN_SUSPENS, TAG_PES],
  },
  {
    url: "/dashboard/dossiers/[slug]/pes-gestion/a-valider",
    h1: "Points en suspens à valider",
    subtitle: "Tous les points en suspens à l'étape de création et de modification",
    restricted: false,
    isCompanySlugRouteRequired: true,
    namespace: "company",
    tags: [TAG_COMPANY, TAG_POINT_EN_SUSPENS, TAG_PES],
  },
  {
    url: "/dashboard/dossiers/[slug]/pes-gestion/archives",
    h1: "Points en suspens archivés",
    subtitle: "Etape préalable à la suppression définitive d'une question",
    restricted: false,
    isCompanySlugRouteRequired: true,
    namespace: "company",
    tags: [TAG_COMPANY, TAG_POINT_EN_SUSPENS, TAG_PES],
  },
  {
    url: "/dashboard/dossiers/[slug]/pes-gestion/en-attente",
    h1: "Points en suspens en attente",
    subtitle:
      "Points en suspens envoyés au client et n'ayant pas encore reçus de réponses et points en suspens avec une demande de complément",
    restricted: false,
    isCompanySlugRouteRequired: true,
    namespace: "company",
    tags: [TAG_COMPANY, TAG_POINT_EN_SUSPENS, TAG_PES],
  },
  {
    url: "/dashboard/dossiers/[slug]/pes-gestion/traitees",
    h1: "Points en suspens traités",
    subtitle: "Points en suspens dont la réponse a été jugée suffisante",
    restricted: false,
    isCompanySlugRouteRequired: true,
    namespace: "company",
    tags: [TAG_COMPANY, TAG_POINT_EN_SUSPENS, TAG_PES],
  },
  {
    url: "/dashboard/dossiers/[slug]/tableau-de-bord",
    h1: "Tableau de bord",
    subtitle: "Indicateur de performance du dossier",
    restricted: false,
    isCompanySlugRouteRequired: true,
    namespace: "company",
    tags: [TAG_COMPANY, TAG_DATA, TAG_KPI, TAG_GRAPHIQUE],
  },
  {
    url: "/dashboard/dossiers/[slug]/transmission-pieces",
    h1: "Transmissions de pièces-jointes",
    subtitle: "Gérez l'envoi des pièces lors du traitement d'une question",
    restricted: false,
    isCompanySlugRouteRequired: true,
    namespace: "company",
    tags: [TAG_COMPANY],
  },
  {
    url: "/dashboard/gestion-cabinet/categories",
    h1: "Gestion des catégories",
    subtitle: "Gestion des catégories relatives au cabinet",
    restricted: true,
    namespace: "accountingFirm",
    tags: [TAG_ACCOUNTING_FIRM],
  },
  {
    url: "/dashboard/gestion-cabinet/collaborateurs/equipes/creation",
    h1: "Création d'équipes",
    subtitle: "Création d'une nouvelle équipe pour le cabinet afin de réorganiser les utilisateurs",
    restricted: true,
    namespace: "accountingFirm",
    tags: [TAG_ACCOUNTING_FIRM, TAG_ACCOUNTING_FIRM_USERS, TAG_CREATION, TAG_TEAM],
  },
  {
    url: "/dashboard/gestion-cabinet/collaborateurs/equipes",
    h1: "Listing équipe",
    subtitle: "Liste de toutes les équipes liées à votre cabinet",
    restricted: true,
    namespace: "accountingFirm",
    tags: [TAG_ACCOUNTING_FIRM, TAG_ACCOUNTING_FIRM_USERS, TAG_TEAM],
  },
  {
    url: "/dashboard/gestion-cabinet/collaborateurs/gestion/creation",
    h1: "Création collaborateur",
    subtitle: "Création d'un nouveau collaborateur pour le cabinet",
    restricted: true,
    namespace: "accountingFirm",
    tags: [TAG_ACCOUNTING_FIRM, TAG_ACCOUNTING_FIRM_USERS, TAG_CREATION],
  },
  {
    url: "/dashboard/gestion-cabinet/collaborateurs",
    h1: "Gestion des collaborateurs",
    subtitle: "Gestion des collaborateurs du cabinet",
    restricted: true,
    namespace: "accountingFirm",
    tags: [TAG_ACCOUNTING_FIRM, TAG_ACCOUNTING_FIRM_USERS],
  },
  {
    url: "/dashboard/gestion-cabinet/connecteurs",
    h1: "Gestion des connecteurs",
    subtitle: "Création et configuration des connecteurs",
    restricted: true,
    namespace: "accountingFirm",
    tags: [TAG_ACCOUNTING_FIRM, TAG_API, TAG_CONNECTOR],
  },
  {
    url: "/dashboard/gestion-cabinet/facturation/moyens-de-paiement",
    h1: "Gestion des moyens de paiements",
    subtitle: "Ajout et modification de vos moyens de paiements",
    restricted: true,
    namespace: "accountingFirm",
    tags: [TAG_ACCOUNTING_FIRM, TAG_FACTURATION],
  },
  {
    url: "/dashboard/gestion-cabinet/facturation/options",
    h1: "Mes options",
    subtitle: "Consultation et souscription des options disponibles",
    restricted: true,
    namespace: "accountingFirm",
    tags: [TAG_ACCOUNTING_FIRM, TAG_FACTURATION],
  },
  {
    url: "/dashboard/gestion-cabinet/facturation",
    h1: "Gestion facturation",
    subtitle: "Consultation de la facturation lié à l'utilisation de Syndemos",
    restricted: true,
    namespace: "accountingFirm",
    tags: [TAG_ACCOUNTING_FIRM, TAG_FACTURATION],
  },
  {
    url: "/dashboard/gestion-cabinet/facturation/regularisation",
    h1: "Régularisation de votre compte",
    subtitle:
      "Si vos informations de paiement ne sont pas correctement renseignées ou que le cabinet est bloqué faute de paiement",
    restricted: true,
    namespace: "accountingFirm",
    tags: [TAG_ACCOUNTING_FIRM, TAG_FACTURATION],
  },
  {
    url: "/dashboard/gestion-cabinet/historique",
    h1: "Historique du cabinet",
    subtitle: "Liste de toutes les actions du cabinet par dossier et collaborateur",
    restricted: true,
    namespace: "accountingFirm",
    tags: [TAG_ACCOUNTING_FIRM],
  },
  {
    url: "/dashboard/gestion-cabinet/modeles-de-questions/creation",
    h1: "Création d'un nouveau modèle de questions",
    subtitle: "Création d'un nouveau modèle personnalisé",
    restricted: true,
    namespace: "accountingFirm",
    tags: [TAG_ACCOUNTING_FIRM, TAG_CREATION],
  },
  {
    url: "/dashboard/gestion-cabinet/modeles-de-questions",
    h1: "Modèles de questions",
    subtitle: "Créer et gérer des formulaires prêt à être envoyés à vos clients",
    restricted: true,
    namespace: "accountingFirm",
    tags: [TAG_ACCOUNTING_FIRM],
  },
  {
    url: "/dashboard/gestion-cabinet",
    h1: "Gestion du cabinet",
    subtitle: "Consultation des informations du cabinet et du résumé des activités intrisèques à celui-ci",
    restricted: true,
    namespace: "accountingFirm",
    tags: [TAG_ACCOUNTING_FIRM],
  },
  {
    url: "/dashboard/gestion-cabinet/suggestions-reponses",
    h1: "Suggestions de réponses",
    subtitle: "Gérer ici les suggestions de réponses en lien avec les catégories",
    restricted: true,
    namespace: "accountingFirm",
    tags: [TAG_ACCOUNTING_FIRM],
  },
  {
    url: "/dashboard/mes-informations-personnelles/connecteurs",
    h1: "Mes informations de connexions connecteurs",
    subtitle: "Gestion de vos mots de passes utilisateurs spécifiques aux connecteurs",
    restricted: false,
    namespace: "myprofil",
    tags: [TAG_API, TAG_CONNECTOR, TAG_MYPROFIL],
  },
  {
    url: "/dashboard/mes-informations-personnelles/connexions",
    h1: "Mes informations de connexion",
    subtitle: "Liste de mes connexions",
    restricted: false,
    namespace: "myprofil",
    tags: [TAG_MYPROFIL],
  },
  {
    url: "/dashboard/mes-informations-personnelles/notifications",
    h1: "Mes notifications",
    subtitle: "Liste de mes notifications",
    restricted: false,
    namespace: "myprofil",
    tags: [TAG_API, TAG_CONNECTOR, TAG_MYPROFIL],
  },
  {
    url: "/dashboard/mes-informations-personnelles/notifications-push",
    h1: "Configuration des notifications push",
    subtitle: "Modifier vos règles en lien avec vos notifications push",
    restricted: false,
    namespace: "myprofil",
    tags: [TAG_MYPROFIL],
  },

  {
    url: "/dashboard/mes-informations-personnelles",
    h1: "Informations personnelles",
    subtitle: "Modification de mes informations personnelles",
    restricted: false,
    namespace: "myprofil",
    tags: [TAG_MYPROFIL],
  },
  {
    url: "/dashboard/mes-notifications",
    h1: "Mes notifications",
    subtitle: "Consultation de mes notifications",
    restricted: false,
    namespace: "myprofil",
    tags: [TAG_MYPROFIL],
  },
  {
    url: "/dashboard",
    h1: "Espace cabinet",
    subtitle: "Gestion du cabinet et des dossiers associés",
    restricted: false,
    namespace: "accountingFirm",
    tags: [TAG_ACCOUNTING_FIRM, TAG_KPI, TAG_DATA, TAG_GRAPHIQUE],
  },
  {
    url: "/dashboard/tableaux-de-bord/creation",
    h1: "Création des tableaux de bord du cabinet",
    subtitle: "Création de tableaux de bords de personnalisés",
    restricted: false,
    namespace: "accountingFirm",
    tags: [TAG_ACCOUNTING_FIRM, TAG_KPI, TAG_DATA, TAG_GRAPHIQUE, TAG_CREATION],
  },
  {
    url: "/dashboard/tableaux-de-bord",
    h1: "Gestion des tableaux de bord du cabinet",
    subtitle: "Consultation des modèles de tableaux de bord du cabinet",
    restricted: false,
    namespace: "accountingFirm",
    tags: [TAG_ACCOUNTING_FIRM, TAG_KPI, TAG_DATA, TAG_GRAPHIQUE],
  },

  {
    url: "/informations/aide",
    h1: "Centre d'aide et support",
    subtitle:
      "Consultez les tutoriaux ressources et FAQ à votre disposition afin de mieux appréhender l'outil Syndemos",
    restricted: false,
    namespace: "information",
    tags: [TAG_INFORMATIONS],
  },
  {
    url: "/informations/annexe-cgv",
    h1: "Annexe",
    subtitle: "Annexe aux conditions générales de vente",
    restricted: false,
    namespace: "information",
    tags: [TAG_INFORMATIONS],
  },
  {
    url: "/informations/annonces",
    h1: "Annonces",
    subtitle: "Retrouvez ici les annonces publiées par Syndemos",
    restricted: false,
    namespace: "information",
    tags: [TAG_INFORMATIONS],
  },
  {
    url: "/informations/cgu",
    h1: "Conditions générales d'utilisations",
    subtitle: "Consultez les informations relatives à l'utilisation de Syndemos",
    restricted: false,
    namespace: "information",
    tags: [TAG_INFORMATIONS],
  },
  {
    url: "/informations/cgv",
    h1: "Conditions générales de vente",
    subtitle: "Les règles qui régissent les contrats de Syndemos",
    restricted: false,
    namespace: "information",
    tags: [TAG_INFORMATIONS],
  },
  {
    url: "/informations/mentions-legales",
    h1: "Informations légales",
    subtitle: "",
    restricted: false,
    namespace: "information",
    tags: [TAG_INFORMATIONS],
  },
  {
    url: "/informations",
    h1: "Informations",
    subtitle: "Retrouvez toutes les informations légales et documentations",
    restricted: false,
    namespace: "information",
    tags: [TAG_INFORMATIONS],
  },
  {
    url: "/informations/politique-des-cookies",
    h1: "Politique de cookies",
    subtitle: "",
    restricted: false,
    namespace: "information",
    tags: [TAG_INFORMATIONS],
  },
  {
    url: "/informations/ressources",
    h1: "Ressources",
    subtitle: "Retrouvez les différents fichiers et sources utiles",
    restricted: false,
    namespace: "information",
    tags: [TAG_INFORMATIONS],
  },
  {
    url: "/informations/tickets/creer",
    h1: "Créer nouveau ticket",
    subtitle: "Singaler un incident sur le site ou proposer une amélioration",
    restricted: false,
    namespace: "report",
    tags: [TAG_INFORMATIONS],
  },
  {
    url: "/informations/tickets",
    h1: "Tickets",
    subtitle: "Consultation de vos tickets",
    restricted: false,
    namespace: "report",
    tags: [TAG_INFORMATIONS],
  },
  {
    url: "/informations/tutos",
    h1: "Tutos",
    subtitle: "Retrouvez nos formations et tutoriaux concernant l'utilisation de Syndemos",
    restricted: false,
    namespace: "information",
    tags: [TAG_INFORMATIONS],
  },
  {
    url: "/informations/versions-et-nouveautes",
    h1: "Versions et nouvelles fonctionnalités",
    subtitle: "Liste des fonctionnalités, corrections et améliorations par versions",
    restricted: false,
    namespace: "information",
    tags: [TAG_INFORMATIONS],
  },
] as const;
